.product-carousel .card {
  flex: 1;
  width: calc(100% / 6);
  max-width: calc(16.666% - 20px);
  height: 380px;  /* Augmentons légèrement la hauteur */
  width: 200px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
}


.product-carousel .card-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.product-carousel h3 {
  font-size: 0.9rem;
  line-height: 1.2;
  height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin-bottom: 0.5rem;
}

.product-carousel .text-lg {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-carousel .amazon-btn {
  margin-top: 10px;
  width: 100%;
}

.product-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.products-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.product-carousel .card {
  flex: 0 0 auto;
  width: calc(100% / 6);
  max-width: none;
  margin: 0;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0,0,0,0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

@media (max-width: 1200px) {
  .product-carousel .card {
    max-width: calc(25% - 20px); /* Pour 4 cartes sur les écrans moyens */
  }
}

@media (max-width: 768px) {
  .product-carousel .card {
    max-width: calc(50% - 20px); /* Pour 2 cartes sur les petits écrans */
  }
}


.card-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 160px;
  margin-left: 5em;
  margin-right: 5em;
}
