.product-carousel .card {
  flex-direction: column;
  flex: 1;
  width: 200px;
  max-width: calc(16.666% - 20px);
  height: 380px;
  margin: 0 10px;
  display: flex;
}

.product-carousel .card-content {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.product-carousel h3 {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  white-space: normal;
  -webkit-box-orient: vertical;
  height: 2.4em;
  margin-bottom: .5rem;
  font-size: .9rem;
  line-height: 1.2;
  display: -webkit-box;
  overflow: hidden;
}

.product-carousel .text-lg {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.product-carousel .amazon-btn {
  width: 100%;
  margin-top: 10px;
}

.product-slider {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.products-container {
  justify-content: space-between;
  width: 100%;
  transition: transform .3s ease-in-out;
  display: flex;
}

.product-carousel .card {
  flex: none;
  width: 16.6667%;
  max-width: none;
  margin: 0;
}

.arrow {
  color: #fff;
  cursor: pointer;
  z-index: 10;
  background: #00000080;
  border: none;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

@media (width <= 1200px) {
  .product-carousel .card {
    max-width: calc(25% - 20px);
  }
}

@media (width <= 768px) {
  .product-carousel .card {
    max-width: calc(50% - 20px);
  }
}

.card-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 100%;
  margin-left: 5em;
  margin-right: 5em;
  display: flex;
}
/*# sourceMappingURL=index.93bd5b46.css.map */
